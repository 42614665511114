<template>
  <div v-loading="loaders.bariResourceSubCategories" class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <add-edit-bari-resource-sub-category @refreshBariResourceSubCategories="getBariResourceSubCategories"
                                         :sub-category-id="editId"
                                         @clearEditId="editId = 0" :action-button-text="modalActionButtonText"
                                         :modal-title="modalTitle"/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">List of Sub Categories</h1>
      <div class="d-flex justify-content-end">
        <base-button class="btn-secondary-action title-btn-padding button-font" @click="handleAdd">
          Add New
        </base-button>
      </div>
    </div>
    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.bariResourceSubCategories"
        >Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.bariResourceSubCategories"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.bariResourceSubCategories"
        >Deactivate
        </base-button>
      </div>
      <el-table
        class="table-responsive table-flush"
        header-row-class-name="thead-light"
        width="100%"
        ref="multipleSelectionTable"
        :data="response.bariResourceSubCategories"
        @selection-change="bariResourceSubCategoriesMultiSelect"
      >
        <el-table-column prop="selected" type="selection" :min-width="15">
        </el-table-column>
        <el-table-column label="CATEGORY NAME" :min-width="25">
          <template slot-scope="scope">
            <div class="ellipsis-overflow-text">{{ scope.row.category.name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="SUB CATEGORY NAME" :min-width="25">
          <template slot-scope="scope">
            <div class="ellipsis-overflow-text">{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" prop="status" :min-width="20">
          <template slot-scope="scope">
              <span :class="['status',getStatusClass(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatusClass(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" :min-width="15">
          <div slot-scope="{ $index, row }" class="d-flex">
            <el-tooltip
              class="item" effect="dark"
              content="Edit"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <img
                class="pointer"
                @click="handleEdit($index, row)"
                src="/img/icons/buttons/edit.svg"
                alt="Edit"
              />
            </el-tooltip>
            <el-tooltip
              class="item" effect="dark"
              content="Delete"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <img
                class="pointer ml-2"
                @click="handleDelete($index, row)"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
              />
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
      <div class="col-12 d-flex justify-content-end flex-wrap">
        <custom-pagination
          class="mt-4 pagination-no-border float-right"
          v-model="request.pagination.page"
          :per-page="request.pagination.perPage"
          :total="request.pagination.total"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeBariResourceSubCategory"
        @onRemoveMultiple="deleteBariResourceSubCategories"
      />
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui"
import BackButton from "@/components/Router/BackButton"
import CustomPagination from "@/views/Components/Pagination/CustomPagination";
import AddEditBariResourceSubCategory
  from "@/views/Pages/SuperAdmin/BariResource/BariResourceSubCategory/AddEditBariResourceSubCategory";

const RemoveModal = () => import(/* webpackChunkName: "dietCategoriesRemoveModal" */ "@/components/Modals/RemoveModal.vue")

export default {
  name: "BariResourceSubCategories",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal, BackButton, CustomPagination,
    AddEditBariResourceSubCategory
  },
  data() {
    return {
      loaders: {
        bariResourceSubCategories: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      response: {
        bariResourceSubCategories: [],
      },
      request: {
        pagination: {
          page: 1,
          currentPage: 1,
          perPage: 5,
          total: 1,
        }
      },
      editId: 0,
      modalActionButtonText: 'Save',
      modalTitle: 'Add Bari Resource Sub Category'
    }
  },
  computed: {
    /**
     * Extract the ids from selected rows from table
     * @returns {*[]}
     */
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  },
  mounted() {
    this.getBariResourceSubCategories()
  },
  methods: {
    /**
     * Handle edit event
     * @param index
     * @param row
     */
    handleEdit(index, row) {
      this.editId = row.id
      this.modalTitle = 'Edit Bari Resource Sub Category'
      this.modalActionButtonText = 'Update'
      this.toggleAddEditModal()
    },

    /**
     * Handle Add event
     */
    handleAdd() {
      this.editId = 0
      this.modalTitle = 'Add Bari Resource Sub Category'
      this.modalActionButtonText = 'Save'
      this.toggleAddEditModal()
    },

    /**
     * Toggle Add/Edit Modal
     */
    toggleAddEditModal() {
      this.$store.commit('BariResourceModule/toggleAddEditBariResourceSubCategoryModal', true)
    },

    /**
     * Get Bari Resource Sub Categories
     */
    getBariResourceSubCategories() {
      let vm = this
      vm.loaders.bariResourceSubCategories = true
      const payload = {
        page: vm.request.pagination.currentPage,
      }
      vm.$store.dispatch('BariResourceModule/_getBariResourceSubCategories', payload)
        .then(response => {
          vm.response.bariResourceSubCategories = response.data.data.data
          vm.request.pagination.currentPage = response.data.data.current_page
          vm.request.pagination.total = response.data.data.total
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Bari Resource Sub Categories',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.bariResourceSubCategories = false
        })
    },

    /**
     * Prompt multiple remove
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove these Bari Resource Sub Categories.'
      this.removeId = this.extractSelectedIds
    },

    /**
     * Handle activate function
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    /**
     * Handle deactivate function
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Update the status for selected row(s) from table
     * @param ids
     * @param status
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.bariResourceSubCategories = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      }
      vm.$store.dispatch('BariResourceModule/_updateStatusBariResourceSubCategories', payload)
        .then(response => {
          vm.removeId = []
          vm.$notify.success({
            title: 'Success',
            message: 'Status Updated Successfully.'
          })
          vm.getBariResourceSubCategories()
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Status Bari Resource Sub Categories',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.bariResourceSubCategories = false
        })
    },

    /**
     * Handle multi select event
     * @param selectedRows
     */
    bariResourceSubCategoriesMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Get status class
     * @param status
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'Inactive-Status' : 'Active-Status'
    },

    /**
     * Get status as string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Handle delete event
     * @param index
     * @param row
     */
    handleDelete(index, row) {
      this.promptRemove(row)
    },

    /**
     * Prompt remove modal
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this Bari Resource Sub Category.'
      this.removeId = row.id
    },

    /**
     * Change page event
     * @param page
     */
    changePage(page) {
      if (page != this.request.pagination.page) {
        this.request.pagination.page = page
        this.getBariResourceSubCategories()
      }
    },

    /**
     * Handle remove single item event
     * @param id
     */
    removeBariResourceSubCategory(id) {
      this.deleteRequest(id)
    },

    /**
     * Handle remove multiple item event
     */
    deleteBariResourceSubCategories() {
      this.deleteRequest(this.removeId)
    },

    /**
     * Delete Bari Resource Sub Category(s)
     * @param data
     */
    deleteRequest(data) {
      let vm = this
      vm.loaders.bariResourceSubCategories = true
      vm.loaders.checkboxButton.Delete = true
      const payload = {ids: Array.isArray(data) ? data : [data]}
      vm.$store.dispatch('BariResourceModule/_deleteBariResourceSubCategories', payload)
        .then(response => {
          vm.removeId = []
          vm.$store.commit("hideRemoveModal")
          vm.$notify.success({
            title: 'Success',
            message: 'Bari Resource Sub Category(s) Deleted Successfully!'
          })
          vm.getBariResourceSubCategories()
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Bari Resource Sub Category(s)',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.checkboxButton.Delete = false
          vm.loaders.bariResourceSubCategories = false
        })
    },
  }
}
</script>

<style scoped>

</style>
